import React from 'react'
import { parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js'
import mime from 'mime-types'
import {
  imageFileMimeTypes,
  applicationFileMimeTypes,
  audioFileMimeTypes,
  textFileMimeTypes,
  videoFileMimeTypes,
} from '../constants/const'

export const isNumeric = (value) => {
  return /^\d+$/.test(value)
}

export const convertDateTime = (time) => {
  const date = new Date(time)
  const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let year = date.getFullYear()
  let month = monthArray[date.getMonth()]
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()

  let ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = month + ' ' + day + ', ' + year + ' - ' + hours + ':' + minutes + ' ' + ampm
  return strTime
}

export const convertScheduleDateTime = (time) => {
  const date = new Date(time)
  const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let year = date.getFullYear()
  let month = monthArray[date.getMonth()]
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = month + ' ' + day + ', ' + year + ' at ' + hours + ':' + minutes + ' ' + ampm
  return strTime
}

export const phoneNumFormat = (number) => {
  if (number) {
    const phone_number = parsePhoneNumberFromString(number)
    if (!phone_number) {
      return number
    } else {
      const phoneNumber = phone_number.formatNational()
      return phoneNumber
    }
  } else return number
}

export const setFormatDate = (date, type) => {
  let formatted_date = null
  if (type === 'start') {
    formatted_date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
  }
  if (type === 'end') {
    formatted_date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)
  }
  return formatted_date
}

export const formatDate = (date, fullMonth, reportDate) => {
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  const day = date.getDate()
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  if (fullMonth) {
    month = monthNames[date.getMonth()]
  }
  let formattedDate = year + '-' + month + '-' + day
  if (reportDate) {
    formattedDate = month + ' ' + day + ', ' + year
  }
  return formattedDate
}

export const getMedia = (url) => {
  const mimeType = mime.lookup(url)
  if (imageFileMimeTypes.includes(mimeType)) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={url} className="img-view" alt="download" />
      </a>
    )
  } else if (audioFileMimeTypes.includes(mimeType)) {
    return (
      <audio controls>
        <source src={url}></source>
      </audio>
    )
  } else if (videoFileMimeTypes.includes(mimeType)) {
    return (
      <video className="img-view" width="200" height="150" controls>
        <source src={url} type={mimeType}></source>
      </video>
    )
  } else if (textFileMimeTypes.includes(mimeType)) {
    return <link href={url} className="img-view" rel="stylesheet" type={mimeType} />
  } else if (applicationFileMimeTypes.includes(mimeType)) {
    return <embed src={url} width="200px" height="200px" />
  }
  return
}

export const getMemberName = (member) => {
  if (member?.contacts?.length === 0) return ''
  if (member?.contacts?.length >= 1) return member?.contacts[0].labelName
}

export const getCompanyName = (member) => {
  if (member?.contacts?.length === 0) return ''
  if (member?.contacts?.length >= 1) return member?.contacts[0].company
}

export const getProfileAvatar = (member) => {
  if (member?.contacts?.length === 0) return ''
  if (member?.contacts?.length >= 1) return member?.contacts[0].profileUrl
}

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const getDateTime = (timestamp) => {
  const stamp = new Date((timestamp - 62167219200) * 1000)
  const year = stamp.getFullYear()
  const month = stamp.getMonth() + 1
  const fmonth = '0' + month
  const date = '0' + stamp.getDate()
  const hours = '0' + stamp.getHours()
  const minutes = '0' + stamp.getMinutes()
  const seconds = '0' + stamp.getSeconds()
  const formattedDate = year + '-' + fmonth.substr(-2) + '-' + date.substr(-2)

  const formattedTime = hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
  const dateTime = { date: formattedDate, time: formattedTime }
  return dateTime
}

export const getDuration = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60)
  let seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60)
  seconds = Math.round(seconds * 100) / 100

  let result = ''
  if (hours !== 0) {
    result += (hours < 10 ? '0' + hours : hours) + ':'
  }
  result += (minutes < 10 ? '0' + minutes : minutes) + ':'
  result += seconds < 10 ? '0' + seconds : seconds

  return result
}

export const getPhoneNumber = (number) => {
  let phone_number = ''
  if (!number.includes('+')) {
    if (number.length === 11) {
      phone_number = parsePhoneNumber('+' + number)
      let phone_num = phone_number.formatInternational()
      return phone_num
    } else if (number.length === 10) {
      phone_number = parsePhoneNumber('+1' + number)
      let phone_num = phone_number.formatInternational()
      return phone_num
    } else {
      return number
    }
  } else {
    phone_number = parsePhoneNumber(number)
    let phone_num = phone_number.formatInternational()
    return phone_num
  }
}

export const phoneNumberFormat = (number) => {
  if (number) {
    const phone_number = parsePhoneNumberFromString(number)
    if (!phone_number) {
      var cleaned = ('' + number).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    } else {
      const phoneNumber = phone_number.formatNational()
      return phoneNumber
    }
  } else return number
}

export const convertCallTime = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600)
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
  let seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60)
  seconds = Math.round(seconds * 100) / 100

  let result = ''
  if (hours !== 0) {
    result += (hours < 10 ? '0' + hours : hours) + ':'
  }
  result += (minutes < 10 ? '0' + minutes : minutes) + ':'
  result += seconds < 10 ? '0' + seconds : seconds
  return result
}
