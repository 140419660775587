import axios from 'axios'
import CONFIG from '../constants/config'
import { logOut } from './auth.action'
import * as CONSTS from '../constants/const'
import { toast } from 'react-toastify'
import moment from 'moment'

export const getCallFlow = (start, end) => {
  const startDate = moment(start).unix()
  const endDate = moment(end).unix()

  let start_timestamp = startDate + 62167219200
  let end_timestamp = endDate + 62167219200
  return (dispatch) => {
    let account_id = localStorage.getItem('account_id')
    let user_id = localStorage.getItem('user_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
    const calldata = `${CONFIG.API_URL}/accounts/${account_id}/cdrs/interaction?created_from=${start_timestamp}&created_to=${end_timestamp}&paginate=false`
    const username = `${CONFIG.API_URL}/accounts/${account_id}/users/${user_id}`
    axios
      .all([axios.get(calldata), axios.get(username)])
      .then(
        axios.spread((calldata, username) => {
          let call_data = calldata.data.data
          let full_name = username.data.data.first_name + ' ' + username.data.data.last_name
          var callData = { call_data, full_name }
          dispatch({ type: CONSTS.GET_ALL_CALLFLOW, payload: callData })
        }),
      )
      .catch((error) => {
        if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && error.response.status === 401) {
          dispatch({ type: CONSTS.FAIL_AUTH_REQUEST, payload: 'Auth_Failed' })
          dispatch(logOut())
        }
      })
  }
}

export const getCallDetails = (interaction_id) => {
  return (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')

    const URI = `${CONFIG.API_URL}/accounts/${account_id}/cdrs/legs/${interaction_id}?paginate=false`
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    axios.get(URI).then((res) => {
      dispatch({ type: CONSTS.GET_CALL_HISTORY_DETAILS, payload: res.data.data })
      console.log(res.data.data, 'testing')
    })
  }
}

export const getNotes = (call_interactionId) => {
  return async (dispatch) => {
    await axios
      .post(`${CONFIG.serverURL}/getcallnotes`, {
        call_interactionId: Array.isArray(call_interactionId) ? call_interactionId : [call_interactionId],
        userId: localStorage.getItem('user_id'),
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: CONSTS.GET_CALL_NOTES, payload: res.data })
        }
      })
  }
}

export const saveCallNotes = (notes, interaction_id, created_by, call, number, call_direction, isPrivate) => {
  return async (dispatch) => {
    try {
      const user_id = localStorage.getItem('user_id')
      const [res] = await Promise.all([
        axios.post(`${CONFIG.serverURL}/savecallnotes`, {
          notes: notes,
          interaction_id: interaction_id,
          created_by: created_by,
          number,
          call_direction,
          user_id,
          from: call.caller_id_number,
          to: call.dialed_number,
          callId: call.call_id,
          isPrivate,
        }),
        axios.post(`${CONFIG.serverURL}/createNote`, {
          from: call.caller_id_number,
          to: call.dialed_number,
          body: notes,
          callId: call.call_id,
        }),
      ])
      toast.success('Notes saved', {
        position: toast.POSITION.TOP_RIGHT,
      })
      dispatch({ type: CONSTS.SAVE_CALL_NOTES, payload: res.data })
      dispatch(getNotes(interaction_id))
    } catch (err) {
      toast.error('Notes not saved', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }
}

export const updateCallNotes = (notes, interaction_id, updated_by, call, number, call_direction, isPrivate) => {
  return async (dispatch) => {
    try {
      const user_id = localStorage.getItem('user_id')
      await Promise.all([
        axios.post(`${CONFIG.serverURL}/updatecallnotes`, {
          notes: notes,
          interaction_id: interaction_id,
          updated_by: updated_by,
          number,
          call_direction,
          user_id,
          from: call.caller_id_number,
          to: call.dialed_number,
          callId: call.call_id,
          isPrivate,
        }),
        axios.post(`${CONFIG.serverURL}/createNote`, {
          from: call.caller_id_number,
          to: call.dialed_number,
          body: 'Update : ' + notes,
          callId: call.call_id,
        }),
      ])

      toast.success('Notes updated', {
        position: toast.POSITION.TOP_RIGHT,
      })

      dispatch(getNotes(interaction_id))
    } catch (err) {
      toast.error('Notes not updated', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }
}
