import React from 'react'
import { parsePhoneNumber } from 'libphonenumber-js'
import telIcon from '../../asset/media/svg/telicon-2.2.0.svg'
import NewSms from './NewSms'
import './History.css'
import QuickCall from '../Voicemails/QuickCall'
import AddNotes from './AddNotes'
import { getNotes } from '../../actions/callhistory.action'
import { connect } from 'react-redux'
import CallRecording from './CallRecording'

class HistoryTable extends React.Component {
  constructor(props) {
    super(props)
    this.filterCallList = this.filterCallList.bind(this)
    this.getPhoneNumber = this.getPhoneNumber.bind(this)
    this.formatDuration = this.formatDuration.bind(this)
  }

  filterCallList = (callRecords, perPage, currentPage, filter) => {
    let subCallRecords = []
    let totalRecords = []
    if (callRecords && callRecords.length > 0) {
      if (!filter) {
        for (var index = perPage * currentPage; index < perPage * (currentPage + 1); index++) {
          if (callRecords[index]) {
            subCallRecords.push(callRecords[index])
            this.props.handleTotalChange(callRecords.length)
          }
        }
      } else {
        for (var i = 0; i < callRecords.length; i++) {
          if (callRecords[i]) {
            if (
              callRecords[i].dialed_number.indexOf(filter) >= 0 ||
              callRecords[i].caller_id_number.indexOf(filter) >= 0 ||
              this.getPhoneNumber(callRecords[i].callee_id_name.toUpperCase()).indexOf(filter) >= 0 ||
              this.getPhoneNumber(callRecords[i].caller_id_name.toUpperCase()).indexOf(filter) >= 0
            ) {
              totalRecords.push(callRecords[i])
            }
          }
        }
        for (var indexData = perPage * currentPage; indexData < perPage * (currentPage + 1); indexData++) {
          if (totalRecords[indexData]) {
            subCallRecords.push(totalRecords[indexData])
            this.props.handleTotalChange(totalRecords.length)
          }
        }
      }
    }
    return subCallRecords
  }

  getPhoneNumber = (number) => {
    let phone_number = ''
    var phoneNumber

    if (!number.includes('+')) {
      if (number.length === 11) {
        if (isFinite(String(number))) {
          phone_number = parsePhoneNumber('+' + number)
          let phone_num = phone_number.formatInternational()
          let number_arr = phone_num.split(' ')
          phoneNumber = number_arr[0] + ' ' + number_arr[1] + '-' + number_arr[2] + '-' + number_arr[3]
          return phoneNumber
        } else {
          return number
        }
      } else if (number.length === 10) {
        if (isFinite(String(number))) {
          phone_number = parsePhoneNumber('+1' + number)
          let phone_num = phone_number.formatInternational()
          let number_arr = phone_num.split(' ')
          phoneNumber = number_arr[0] + ' ' + number_arr[1] + '-' + number_arr[2] + '-' + number_arr[3]
          return phoneNumber
        } else {
          return number
        }
      } else {
        return number
      }
    } else {
      if (isFinite(String(number))) {
        phone_number = parsePhoneNumber(number)
        let phone_num = phone_number.formatInternational()
        let number_arr = phone_num.split(' ')
        phoneNumber = number_arr[0] + ' ' + number_arr[1] + '-' + number_arr[2] + '-' + number_arr[3]
        return phoneNumber
      } else {
        return number
      }
    }
  }

  getNumber = (number) => {
    let phone_number = ''
    if (!number.includes('+')) {
      if (number.length === 11) {
        if (isFinite(String(number))) {
          phone_number = parsePhoneNumber('+' + number)
          return phone_number
        } else {
          return number
        }
      } else if (number.length === 10) {
        if (isFinite(String(number))) {
          phone_number = parsePhoneNumber('+1' + number)
          return phone_number
        } else {
          return number
        }
      } else {
        return number
      }
    } else {
      return number
    }
  }
  componentDidUpdate() {
    let callRecords = this.props.list
    let callList = []
    callRecords && callRecords.length > 0 && callRecords.map((call, index) => callList.push(call.interaction_id))
    this.props.getNotes(callList)
  }

  getDateTime = (timestamp) => {
    let stamp = new Date(timestamp * 1000)
    let year = stamp.getFullYear()
    let month = stamp.getMonth() + 1
    let fmonth = '0' + month
    let date = '0' + stamp.getDate()
    let hours = '0' + stamp.getHours()
    let minutes = '0' + stamp.getMinutes()
    let seconds = '0' + stamp.getSeconds()
    let formattedDate = year + '-' + fmonth.substr(-2) + '-' + date.substr(-2)

    let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
    let dateTime = { date: formattedDate, time: formattedTime }
    return dateTime
  }

  formatDuration = (sec) => {
    var date = new Date(null)
    date.setSeconds(sec)
    var timeString = date.toISOString().substr(11, 8)
    timeString = timeString.split(':')[1] + ':' + timeString.split(':')[2]
    return timeString
  }

  render() {
    const ACCOUNT_ID = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    let callRecords = this.props.list
    let perPage = this.props.perPage
    let currentPage = this.props.currentPage
    let filter = this.props.filter
    callRecords = this.filterCallList(callRecords, perPage, currentPage, filter)

    return (
      <div className="call-history-table">
        <div className="row history-table-title">
          <div className="col-md-3">FROM</div>
          <div className="col-md-2">TO</div>
          <div className="col-md-3  action-header">ACTIONS</div>
          <div className="col-md-2">DATE TIME</div>
          <div className="col-md-2">DURATION</div>
        </div>
        {callRecords && callRecords.length > 0 ? (
          callRecords.map((call, index) => (
            <div key={index} className="row call-history-row">
              <div
                className="col-md-3 history-from"
                onClick={() => this.props.history.push('/callhistory/legs/' + call.interaction_id)}
              >
                <div className="mr-3 call-icon">
                  {call.disposition === 'NO_ANSWER' ? (
                    <svg className="missed-icon">
                      <use href={`${telIcon}#phone-missed`} />
                    </svg>
                  ) : (
                    <>
                      {call.authorizing_id ? (
                        <svg className="outcalls-icon">
                          <use href={`${telIcon}#phone-outbound`} />
                        </svg>
                      ) : (
                        <svg className="incalls-icon">
                          <use href={`${telIcon}#phone-inbound`} />
                        </svg>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <div className="text-left name">{call.caller_id_name}</div>
                  <div className="text-left number">{this.getPhoneNumber(call.caller_id_number)}</div>
                </div>
              </div>
              <div
                className="col-md-2"
                onClick={() => this.props.history.push('/callhistory/legs/' + call.interaction_id)}
              >
                <div className="name text-left">{call.callee_id_name}</div>
                <div className="number text-left">{this.getPhoneNumber(call.dialed_number)}</div>
              </div>
              <div className="col-md-3 action-col">
                <div className="number action-btn text-left">
                  {(this.getPhoneNumber(call.caller_id_number).split(' ')[1]?.split('-').join('').length === 10 ||
                    this.getPhoneNumber(call.caller_id_number).split(' ')[0] === '+1') && (
                    <NewSms
                      history={this.props.history}
                      phoneNumber={this.getPhoneNumber(call.caller_id_number)}
                      toNumber={call.caller_id_number}
                      fromNumber={this.getNumber(call.dialed_number)}
                    />
                  )}
                </div>
                <div className="number text-left">
                  <QuickCall
                    history={this.props.history}
                    direction={call.direction}
                    phoneNumber={this.getPhoneNumber(call.caller_id_number)}
                    fromNumber={call.caller_id_number}
                    toNumber={this.getNumber(call.dialed_number)}
                  />
                </div>
                <div className="number action-btn text-left">
                  <AddNotes
                    call={call}
                    fromNumber={call.caller_id_number}
                    toNumber={call.dialed_number}
                    history={this.props.history}
                    isOutboundCall={!!call.authorizing_id}
                    interaction_id={call.interaction_id}
                  />
                </div>
                <div className="number text-left">
                  {call.media_recordings.length > 0 && (
                    <CallRecording
                      ACCOUNT_ID={ACCOUNT_ID}
                      recording={call.media_recordings[0]}
                      auth_token={auth_token}
                    />
                  )}
                </div>
              </div>
              <div
                className="col-md-2"
                onClick={() => this.props.history.push('/callhistory/legs/' + call.interaction_id)}
              >
                <div className="text-left name">{this.getDateTime(call.unix_timestamp).date}</div>
                <div className="text-left number">{this.getDateTime(call.unix_timestamp).time}</div>
              </div>
              <div
                className="col-md-2"
                onClick={() => this.props.history.push('/callhistory/legs/' + call.interaction_id)}
              >
                {this.formatDuration(call.duration_seconds)}
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-12 text-center">
            <h2>No Results!</h2>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // callhistory: state.callhistory,
})

const mapDispatchToProps = (dispatch) => ({
  getNotes: (callList) => dispatch(getNotes(callList)),
})
export default connect(mapStateToProps, mapDispatchToProps)(HistoryTable)
