import React from 'react'
import CONFIG from '../../constants/config'
import silhoutte from '../../asset/media/img/silhoutte.png'

const Header = ({
  printerModal,
  call,
  phoneState,
  isContactInformation,
  setShowInfoPane,
  setMemNumber,
  memberInformation,
  uploadAvatarRef,
  uploadAvatar,
  numbers,
  userName,
}) => {
  return (
    <header className="header-home">
      <div className="header-action">
        <ul className="list-inline">
          <li className="list-inline-item">
            <div className="header-chat-action">
              <div className="chat-icons">
                <div className="btn btn-head mr-2" onClick={printerModal}>
                  <i className="fas fa-print"></i>
                </div>
                <div className={phoneState ? 'btn callPhone-icon mr-2' : ' btn btn-head mr-2'} onClick={call}>
                  <i className="fa fa-phone-alt "></i>
                </div>
                <div
                  className="btn btn-head"
                  onClick={() => {
                    isContactInformation(true, memberInformation)
                    setShowInfoPane(true)
                    setMemNumber(memberInformation.memberNum)
                  }}
                >
                  <i className="fas fa-info"></i>
                </div>
                {/* <div className="btn btn-head position-relative">
                  <i className="fas fa-bell" />
                  <div className="notify-badge" />
                </div> */}
              </div>
              <div className="user-info">
                <figure className="avatar avatar-lg">
                  <div className="chat-user-image">
                    <label id="#bb">
                      <i className="fas fa-upload"></i>
                      <input type="file" ref={uploadAvatarRef} onChange={uploadAvatar} accept="image/*" />
                    </label>
                  </div>
                  {numbers.avatar ? (
                    <img src={`${CONFIG.serverURL}/users/${numbers.avatar}`} alt="avatar" />
                  ) : (
                    <img src={silhoutte} alt="avatar" />
                  )}
                </figure>
                <div>
                  <h5>{userName.fullName}</h5>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header
